@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://api.fontshare.com/v2/css?f[]=satoshi@700,500,300&display=swap"); */
* {
    max-width: 100%;
  }

@layer base {
    h1 {
        @apply text-[32px]/[42px] tablet:text-[40px]/[54px] desktop:text-[50px]/[64px];
    }
    h2 {
        @apply text-[30px]/[40px] tablet:text-[38px]/[48px] desktop:text-[48px]/[55px];
    }
    h3 {
        @apply text-[24px]/[36px] tablet:text-[30px]/[36px] desktop:text-[36px]/[48px];
    }
    body {
        @apply text-[16px]/[24px] tablet:text-[18px]/[30px] desktop:text-[20px]/[30px];
    }
    nav {
        @apply text-[16px]/[24px];
    }

    label {
        @apply text-[14px]/[18px]
    }
}

.Toastify__toast{
    background-color: #F1FBF8;
}
